<app-header (messageEvent)="scrollToElementClicked($event)" hideList="none" showItem="block"></app-header>
<img id="hero-img" class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/bhagwat%2Fshri%20krishan.jpg?alt=media&token=4f7d4ab4-ea2a-4ca9-a746-0e91bd06fe44" alt="Shrimad Bhagwat at Vrinda Anandam">

<div class="row py-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <div class="row mb-3 ml-5" style="width: fit-content;">
                    <h1>Shrimad Bhagwat</h1>
                </div>
                <div class="row">
                    <h1></h1>
                </div>
                <div class="row">
                    <!-- <h4 style="padding-left:65px;text-transform: inherit;">Abode of Lord Krishna</h4>-->
                    <p id="bhagwat-about">
                        Shrimad Bhagwat also known as the Bhagwat Purana, is one of the greatest Purana’s. It is a book about Narayana, his avatars and the detailed account of Lord Krishna. The Shrimad Bhagwat has the accounts of Mahabharata (Krishna Avatar), Ramayana (Rama
                        Avtar) and the Narasimha Avatar of Lord Vishnu among others.
                        <br><br> A conscientious endeavour to convey this rich resource of knowledge in the divine plays of Shri Krishna via Shrimad Bhagwat Katha – the Spiritual Saga. It has the Salutations to Shri Krishna – who is Existence Absolute,
                        Knowledge Absolute and Bliss Absolute; who manifested Himself in a human garb only for the benefit of mankind, to destroy wickedness and to establish righteousness.
                    </p>
                </div>
            </div>
            <div class="col-sm-6" style="align-self: center;">
                <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/bhagwat%2Fmahapuran.jpg?alt=media&token=e178cf29-4ac7-4ed4-a61e-79fc3eaeaec4" alt="Shrimat Bhagwat Mahapuran at Vrinda Anandam">
            </div>
        </div>
    </div>
</div>
<!-- about section ends-->
<app-bhagvat-shrimad></app-bhagvat-shrimad>

<div class="row py-5">
    <div class="container">
        <div class="col-sm-12 my-2">
            <h1 style="text-align: center;">SHRIMAD BHAGWAT AT VRINDA ANANDAM</h1>
        </div>
        <div class="col-sm-12 mt-2 mb-5">
            <h3 style="text-transform:inherit;text-align: center;"></h3>
        </div>
        <div class="row">
            <div class="col-sm mx-3">
                <div class="row" style="object-fit: contain; height: 232px;">
                    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/bhagwat%2FOutdoor.jpg?alt=media&token=86efb20c-24c4-491f-ba42-b3e254f0bc52" alt="Outdoor Venue at Vrinda Anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h4 style="color:#ebb541; font-weight: 300;">Outdoor Venue</h4>
                    </div>
                    <p style="font-size: 15px;">Our outdoor lawns with a capacity of over 800 pax is ideal for hosting Bhagvat Katha for the community.
                    </p>
                </div>
            </div>
            <div class="col-sm mx-3">
                <div class="row" style="object-fit: contain; height: 232px; overflow-y: hidden;">
                    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/bhagwat%2FIndoor.jpg?alt=media&token=8b826ccf-7784-4cdd-b223-7063536cb0ef" alt="Indoor Venue at Vrinda Anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h4 style="color:#ebb541;font-weight: 300;">Indoor Venue</h4>
                    </div>
                    <p style="font-size: 15px;">The banquet hall is perfect for hosting a Bhagwat Katha for family and friends with a capacity of 100 pax to 350 pax.</p>
                </div>
            </div>
            <div class="col-sm mx-3">
                <div class="row" style="object-fit: contain; height: 232px;">
                    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/resized%2Fbhagwat-apart.jpg?alt=media&token=d0c02d0e-ae31-48ed-bd2d-71d8d1287d28" alt="Accomodation at Vrinda Anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h4 style="color:#ebb541;font-weight: 300;">Accommodation</h4>
                    </div>
                    <p style="font-size: 15px;">With over 80 rooms on the property and with a capacity of 250 people, get the most wholesome Bhagwat Katha experience.</p>
                </div>
            </div>

        </div>
    </div>
</div>

<app-sutra></app-sutra>
<app-contact></app-contact>
<app-scroll-top></app-scroll-top>
<app-footer (messageEvent)="scrollToElementClicked($event)"></app-footer>
