import { ErrorComponent } from './component/error/error.component';
import { ThankyouComponent } from './component/thankyou/thankyou.component';
import { BhagvatComponent } from './component/bhagvat/bhagvat.component';
import { WeddingComponent } from './component/wedding/wedding.component';
import { GalleryComponent } from './component/gallery/gallery.component';
import { VideoComponent } from './component/video/video.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: 'home', component: VideoComponent },
  { path: 'wedding', component: WeddingComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'bhagwat', component: BhagvatComponent },
  { path: 'thankyou', component: ThankyouComponent },
  { path: '404', component: ErrorComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top', // Add options right here
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
