import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { VideoComponent } from './component/video/video.component';
import { WeddingComponent } from './component/wedding/wedding.component';
import { GalleryComponent } from './component/gallery/gallery.component';
import { ContactComponent } from './component/contact/contact.component';
import { BhagvatComponent } from './component/bhagvat/bhagvat.component';
import { BhagvatShrimadComponent } from './component/bhagvat-shrimad/bhagvat-shrimad.component';
import { SutraComponent } from './component/sutra/sutra.component';
import { CarouselPauseComponent } from './component/carousel-pause/carousel-pause.component';
import { TextCorouselComponent } from './component/text-corousel/text-corousel.component';
import { ScrollTopComponent } from './component/scroll-top/scroll-top.component';
import { ThankyouComponent } from './component/thankyou/thankyou.component';
import { ErrorComponent } from './component/error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    VideoComponent,
    WeddingComponent,
    GalleryComponent,
    ContactComponent,
    BhagvatComponent,
    BhagvatShrimadComponent,
    SutraComponent,
    CarouselPauseComponent,
    TextCorouselComponent,
    ScrollTopComponent,
    ThankyouComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
