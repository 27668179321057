<div class="katha-learn-section">
    <div class="vaidik-section3-img"> </div>
      <div class="container">
        <div class="row katha-learn-section-equal">
          <div class="col-lg-4 offset-lg-1 col-sm-12 col-xs-12">
              <div class="katha-learn-lt">
                             <h2>What does Shrimad Bhagwat give us?
  </h2>
                           </div>
          </div>
          <div class="col-lg-6 col-sm-12 col-xs-12">
              <div class="katha-learn-rt">
                                <p>By mere it’s reading and hearing, existence and liberation becomes affable. The devotee arrives to an unshakable devotion to Lord Krishna. There is no other means superior than this for purification and ablution of mind. As the wolf rushes away at a lion’s roar, all the failings of Kaliyuga are wiped out by the Bhagwat. Just the recitation of Bhagwatam the God lodges in to the hearts of the devotees , never to leave.</p>
                  
          </div>
        </div>
      </div>
    </div>
  </div>