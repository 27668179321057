import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit {
  @ViewChild("postForm") postForm: NgForm;
  loading: boolean = false;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {}

  onCreatePost(postData: { name: string; phone: number; message: string }) {
    this.loading = true;

    // // Send Http request
    this.http
      .post(
        "https://5iq5d1m387.execute-api.us-west-1.amazonaws.com/v1/send-info",
        postData
      )
      .subscribe((responseData) => {

        this.loading = false;
        this.postForm.reset();
        this.router.navigate(["/thankyou"]);
      });
  }
}
