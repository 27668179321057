<app-header (messageEvent)="scrollToElementClicked($event)" showItem="none"></app-header>
<div class="banner">
    <div class="container">
        <div class="banner-text">
            <div class="banner-heading">
                Welcome to the Vrinda Anandam Resort
            </div>
            <div class="banner-sub-heading">
                Ideally located at the HEART OF <strong>VRINDAVAN</strong> The Braj Bhoomi. Vrinda Anandam, defined by sheer elegance and style.<br> A visit to Vrinda Anandam takes your spiritual levels to new heights, it's a real experience.
                <br><br><br><br><br>
            </div>
        </div>
    </div>
</div>
<!-- about section-->
<div class="row py-5">
    <div class="container-fluid">
        <div id="about-heading" class="col-sm-12">
            <h1>Welcome To <strong>Vrindavan</strong></h1>
        </div>
        <div class="row">
            <div class="col-sm mx5">
                <h4 style="padding-left:65px;text-transform: inherit;">Abode of Lord Krishna</h4>
                <p id="video-about">
                    One of the oldest cities on the banks of Yamuna, <strong>Vrindavan</strong> is considered to be one of the most important places of pilgrimage for the devotees of Krishna. It is said that Lord Krishna had spent his childhood in <strong>Vrindavan</strong>.
                    The name of the city has been derived from Vrinda (meaning basil) and van (meaning grove) which perhaps refer to the two small groves at Nidhivan and Seva Kunj.
                    <br><br> The city of <strong>Vrindavan</strong> hosts hundreds of Lord Krishna and Radha temples scattered throughout, the most famous ones being Banke Bihari temple, ISKCON temple. The vibrant surroundings perfectly depict the playful
                    and benevolent nature of Lord Krishna.
                </p>
            </div>
            <div class="col-sm mx-3" style="align-self: center;">
                <div class="row">
                    <div class="col">
                        <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/vrindavan.jpg?alt=media&token=6971e2c0-cf0a-4a25-bc92-14887070ecba" alt="Iskon Temple at Vrindavan">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- about section ends-->
<hr>
<!-- rooms section-->
<div class="row" id="rooms" #rooms>
    <div class="container-fluid">
        <div class="col-sm-12 my-5">
            <h1 style="text-align: center;">VRINDA ANANDAM ROOMS</h1>
        </div>
        <div class="row">
            <div class="col-sm">
                <div class="row">
                    <div class="col px-0">
                        <img width="100%" style="cursor: pointer;" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2Fdeluxe_hero.jpg?alt=media&token=2025555c-e586-4b3c-8d31-a00e13833518" (click)="show()" alt="Super Deluxe Room at Vrinda anandam">
                    </div>
                </div>
            </div>
            <div class="col-sm" style="align-self: center;">
                <div class="row" id="delx-room">
                    <h3 class="mb-4">SUPER DELUXE ROOMS</h3>
                    <p>The Super Delux rooms at Vrinda Anandam have been loaded with all the facilities and amenities required for a comfortable stay. The lush green areas and well planned parks and lawns provide the best getaway experiences close to nature
                        and spirituality.
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm" style="align-self: center;">
                <div class="row" id="super-delx">
                    <div class="col px-0">
                        <img id="image-1" width="100%" style="cursor: pointer;" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2Fsuper_deluxe_hero.jpg?alt=media&token=e31bae6e-0402-46b8-8b2c-224e28b8c6cd" (click)="showSuper()" alt="Premium Rooms at Vrinda anandam">
                        <h3 id="heading-1" class="mb-4">PREMIUM ROOMS</h3>
                        <p id="para-1">The Premium Rooms at Vrinda Anandam are a class apart. Luxury combined with the best in class upholstery, toiletries and amenities right in the lap of nature. The Premium rooms are perfect for a luxurious holiday in the abode of
                            Lord Krishna – <strong>Vrindavan</strong>.</p>
                    </div>
                </div>
            </div>
            <div class="col-sm">
                <div class="row">
                    <div class="col px-0">
                        <img id="image-2" width="100%" style="cursor: pointer;" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2Fsuper_deluxe_hero.jpg?alt=media&token=e31bae6e-0402-46b8-8b2c-224e28b8c6cd" (click)="showSuper()" alt="Super Deluxe Rooms at Vrinda anandam">
                        <h3 id="heading-2" class="mb-4">SUPER DELUXE ROOMS</h3>
                        <p id="para-2">The Super Delux Rooms at Vrinda Anandam are a class apart. Luxury combined with the best in class upholstery, toiletries and amenities right in the lap of nature. The Super Delux rooms are perfect for a luxurious holiday in the
                            abode of Lord Krishna – <strong>Vrindavan</strong>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Modal starts here-->
<div [style.display]="showModalDeluxe ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- <div class="modal-header" >
        <h4 class="modal-title" style="text-align: center;" id="myModalLabel">Deluxe Room</h4>
        <button type="button" class="btn btn-default" data-dismiss="modal" (click)="hide()"><span class="close"> x </span></button>
      </div> -->
            <div class="modal-body">
                <button type="button" class="btn btn-default align-btn" data-dismiss="modal" (click)="hide()"><span class="close" style="opacity:1;color:white;overflow:hidden;font-size: 2.5rem;"> x </span></button>
                <app-carousel-pause [images]="[{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/Delux%20Hero.jpg?alt=media&token=f288a24c-5f1e-4bc2-87f9-901ed0b499a8', alt: 'Super Deluxe Room at Vrinda Anandam'},
                                        {image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2FDelux%201.jpg?alt=media&token=a2e1dfc7-71b4-493f-ad0f-49708d803a91', alt: 'Super Deluxe Room at Vrinda Anandam'},
                                        {image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2FDelux%202.jpg?alt=media&token=7bcf80bd-286b-475a-9dbb-b3cb873df107', alt: 'Super Deluxe Room at Vrinda Anandam'},
                                        {image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2FDelux%203.jpg?alt=media&token=ee65c60e-7262-485c-b049-c125baa7c959', alt: 'Super Deluxe Room at Vrinda Anandam'},
                                        {image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2FDelux%204.jpg?alt=media&token=d7863949-20b4-4937-8bf2-5df373aaedb6', alt: 'Super Deluxe Room at Vrinda Anandam'}]"></app-carousel-pause>
            </div>
        </div>
    </div>
</div>
<div [style.display]="showModalSuperDeluxe ? 'block' : 'none'" class="modal" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- <div class="modal-header">
        <h4 class="modal-title" style="text-align: center; " id="myModalLabel">Super Deluxe Room</h4>
        <button type="button" class="btn btn-default" style="position: absolute;z-index: 999;left: 85%;" data-dismiss="modal" (click)="hideSuper()"><span class="close" style="font-size: 2.5rem;"> x </span></button>
      </div> -->
            <div class="modal-body">
                <button type="button" class="btn btn-default align-btn" data-dismiss="modal" (click)="hideSuper()"><span class="close" style="opacity:1;color:white;overflow:hidden;font-size: 2.5rem;"> x </span></button>
                <app-carousel-pause [images]="[{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/Super%20Delux%20Hero.jpg?alt=media&token=ac19e884-6b7b-4cc5-8d17-014c40d0bf49', alt:'Premium Room at Vrinda Anandam'},
                                        {image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2FDSC07861.jpg?alt=media&token=668b6575-d909-4e16-8e3f-82f646329f28', alt:'Premium Room at Vrinda Anandam'},
                                        {image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2FDSC07881.jpg?alt=media&token=b6d46c63-ceb7-4450-b6a1-5974349018f3', alt:'Premium Room at Vrinda Anandam'},
                                        {image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2FDSC07891.jpg?alt=media&token=25385678-e32f-4a11-9098-aa95b37e9474', alt:'Premium Room at Vrinda Anandam'},
                                        {image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2FDSC08043.jpg?alt=media&token=db2b2b7c-233f-43ce-8edf-173856164b76', alt:'Premium Room at Vrinda Anandam'},
                                        {image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/home%2FIMG_20200209_160638.jpg?alt=media&token=517870fe-2790-47cd-bf82-2f4958941db4', alt:'Premium Room at Vrinda Anandam'}]"></app-carousel-pause>
            </div>

        </div>
    </div>
</div>
<!--Modal ends here-->
<!-- rooms section ends-->

<!-- image section-->
<div id="image-section" class="row py-4">
    <div class="container-fluid" style="padding-left: 2.5rem; padding-right: 2.5rem;">
        <div class="row">
            <div class="col-sm-3 mob-cls">
                <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2FPool.jpg?alt=media&token=c1cea1ad-1884-4339-9b84-84f9f5a870c1" alt="Swimming Pool at Vrinda anandam">
                <h4 style="font-size:15px; color: white; margin: auto;  padding-top: 15px; text-align: center;">POOL</h4>
                <!-- <div class="row" style="width: fit-content;position: absolute; z-index: 999; margin: 0 auto; left: 0; right: 0; top: 40%;">
                    <h4 style="font-size:15px;
            background:black;opacity:0.85;padding:10px;margin-bottom:0;color: white;">POOL</h4>
                </div> -->
                <!-- <div style="margin:25% auto;background: black; width: fit-content;opacity: 0.5;"><h4 style="padding:10px;margin-bottom:0;color: white;">SPA SALON</h4></div> -->
            </div>
            <div class="col-sm-3 mob-cls">
                <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/resized%2Frestaurant-resize.jpg?alt=media&token=023812d5-ef5c-4cba-bc3f-87b737127bb8" alt="Restaurant at Vrinda anandam">
                <h4 style="font-size:15px; color: white; margin: auto; padding-top: 15px; text-align: center;">RESTAURANT</h4>
                <!-- <div class="row" style="width: fit-content;position: absolute; z-index: 999; margin: 0 auto; left: 0; right: 0; top: 40%;">
                    <h4 style="font-size:15px;
            background:black;opacity:0.85;padding:10px;margin-bottom:0;color: white;">RESTAURANT</h4>
                </div> -->
                <!-- <div style="margin:25% auto;background: black; width: fit-content;opacity: 0.5;"><h4 style="padding:10px;margin-bottom:0;color: white;">SPA SALON</h4></div> -->
            </div>
            <div class="col-sm-3 mob-cls">
                <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/resized%2Fparty-resize.jpg?alt=media&token=421ffdd3-5751-402c-892d-3afada288cc6" alt="Outdoor Venue at Vrinda anandam">
                <h4 style="font-size:15px; color: white; margin: auto; padding-top: 15px; text-align: center;">OUTDOOR VENUE</h4>
                <!-- <div class="row" style="width: fit-content;position: absolute; z-index: 999; margin: 0 auto; left: 0; right: 0; top: 40%;">
                    <h4 style="font-size:15px;
            background:black;opacity:0.85;padding:10px;margin-bottom:0;color: white;">OUTDOOR VENUE</h4>
            </div> -->
                <!-- <div style="margin:25% auto;background: black; width: fit-content;opacity: 0.5;"><h4 style="padding:10px;margin-bottom:0;color: white;">SPA SALON</h4></div> -->
            </div>
            <div class="col-sm-3 mob-cls">
                <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/Indoor%20Banquet.jpg?alt=media&token=14092ed3-6b02-4f1d-b6ec-bac8a45ef30b" alt="Indoor Venue at Vrinda anandam">
                <h4 style="font-size:15px; color: white; margin: auto; padding-top: 15px; text-align: center;">INDOOR VENUE</h4>
                <!-- <div class="row" style="width: fit-content;position: absolute; z-index: 999; margin: 0 auto; left: 0; right: 0; top: 40%;">
                    <h4 style="font-size:15px;
            background:black;opacity:0.85;padding:10px;margin-bottom:0;color: white;">INDOOR VENUE</h4>
                </div> -->
                <!-- <div style="margin:25% auto;background: black; width: fit-content;opacity: 0.5;"><h4 style="padding:10px;margin-bottom:0;color: white;">SPA SALON</h4></div> -->
            </div>
        </div>
    </div>
</div>
<!-- image section ends-->

<!-- elegant room section-->
<div class="row py-5" id>
    <div class="container">
        <div class="col-sm-12 my-2">
            <h1 style="text-align: center;">ELEGANT ROOMS</h1>
        </div>
        <div class="col-sm-12 mt-2 mb-5">
            <h3 style="text-transform:inherit;text-align: center;">Unpretentious Luxury</h3>
        </div>
        <div class="row">
            <div class="col-sm mx-3">
                <div class="row" style="display: flex; justify-content: center;">
                    <img width="30%" src="../../../assets/images/parking-icon.png" alt="Car Parking at Vrinda anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h5 style="text-transform: inherit;">Car Parking</h5>
                    </div>
                    <!-- <p style="font-size: 12px;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. software like Aldus PageMaker including versions of Lorem Ipsum</p> -->
                </div>
            </div>
            <div class="col-sm mx-3">
                <div class="row" style="display: flex; justify-content: center;">
                    <img width="30%" src="../../../assets/images/food-icon.png" alt="Restaurant at Vrinda anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h5 style="text-transform: inherit;">Restaurant</h5>
                    </div>
                    <!-- <p style="font-size: 12px;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. software like Aldus PageMaker including versions of Lorem Ipsum</p> -->
                </div>
            </div>
            <div class="col-sm mx-3">
                <div class="row" style="display: flex; justify-content: center;">
                    <img width="30%" src="../../../assets/images/roomservice-icon.png" alt="Room Service at Vrinda anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h5 style="text-transform: inherit;">Room Service</h5>
                    </div>
                    <!-- <p style="font-size: 12px;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. software like Aldus PageMaker including versions of Lorem Ipsum</p> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm mx-3">
                <div class="row" style="display: flex; justify-content: center;">
                    <img width="30%" src="../../../assets/images/pool-icon.png" alt="Swimming Pool at Vrinda anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h5 style="text-transform: inherit;">Swimming Pool</h5>
                    </div>
                    <!-- <p style="font-size: 12px;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. software like Aldus PageMaker including versions of Lorem Ipsum</p> -->
                </div>
            </div>
            <div class="col-sm mx-3">
                <div class="row" style="display: flex; justify-content: center;">
                    <img width="30%" src="../../../assets/images/wifi-icon.png" alt="Wifi at Vrinda anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h5 style="text-transform: inherit;">WiFi</h5>
                    </div>
                    <!-- <p style="font-size: 12px;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. software like Aldus PageMaker including versions of Lorem Ipsum</p> -->
                </div>
            </div>
            <div class="col-sm mx-3">
                <div class="row" style="display: flex; justify-content: center;">
                    <img width="30%" src="../../../assets/images/energy.png" alt="Power Backup at Vrinda anandam">
                </div>

                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h5 style="text-transform: inherit;">Power Backup</h5>
                    </div>
                    <!-- <p style="font-size: 12px;">Lorem Ipsum is simply dummy text of the printing and typesetting industry. software like Aldus PageMaker including versions of Lorem Ipsum</p> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- elegant room section ends-->
<hr>
<!-- flex card section-->
<div class="row" id="packages" #packages>
    <div class="container">
        <div class="col-sm-12 my-5">
            <h1 style="text-align: center;">VRINDA ANANDAM PACKAGES</h1>
        </div>
        <div class="row">
            <div class="col-sm-4 mb-4 ">
                <div class="card">
                    <div class="card-body">
                        <h5 style="text-align: center;" class="card-title">1N/2D @ ₹ 4000 </h5>
                    </div>
                    <img src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/resized%2Fimageedit_1_6940782991.png?alt=media&token=e3c07210-1e87-44ab-90d8-b8612dff82aa" height="280" class="card-img-top" alt="...">
                    <div class="card-body card-body1">
                        <h5 class="text-center">Inclusions</h5>
                        <p class="card-text text-center">Luxurious Super Delux Accommodation, Indian / Continental Breakfast, Swimming Pool, Buffet Lunch, High Tea, Buffet Dinner, Wifi
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 mb-4 ">
                <div class="card">
                    <div class="card-body">
                        <h5 style="text-align: center;" class="card-title">2N/3D @ ₹ 7000 </h5>
                    </div>
                    <img src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/resized%2Fimageedit_2_3723628778.jpg?alt=media&token=da850d1c-332b-4518-b981-20a9243b7ffa" height="280" class="card-img-top" alt="...">
                    <div class="card-body card-body1">
                        <h5 class="text-center">Inclusions</h5>
                        <p class="card-text text-center">Super Delux Room Stay, Breakfast, 24 x 7 Wifi, Buffet Lunch, High Tea, Buffet Dinner, Swimming Pool
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-4 mb-4 ">
                <div class="card">
                    <div class="card-body">
                        <h5 style="text-align: center;" class="card-title">3N/4D @ ₹ 9500 </h5>
                    </div>
                    <img src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/3N4D.jpg?alt=media&token=2e12abf6-cf0e-44d4-8713-30eb325c327b" height="280" class="card-img-top" alt="...">
                    <div class="card-body card-body1">
                        <h5 class="text-center">Inclusions</h5>
                        <p class="card-text text-center">Super Delux Room, Breakfast, 24x7 Wifi, Swimming Pool, Buffet Lunch, Evening Tea and Snacks, Buffet Dinner</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!-- flex card section ends-->
<hr>
<!-- get in touch section-->
<app-contact></app-contact>
<!-- get in touch section ends-->
<!-- WhatsApp -->
<div class="row">
  <a href="https://api.whatsapp.com/send?phone=+919821004532&text=Hello Vrinda Anandam,I would like to enquire about something."
      class="btm_whatsappChat_icn" target="_blank">
      <i class="fa fa-whatsapp btm_whatsapp_icn"></i>
  </a>
</div>
<app-scroll-top></app-scroll-top>

<app-footer (messageEvent)="scrollToElementClicked($event)"></app-footer>
