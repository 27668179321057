<nav class="navbar navbar-expand-md navbar-dark fixed-top" style="background-color: {{colorValue}};" id="banner">
    <div class="container">
        <a class="navbar-brand" routerLink=""><img src="../../../assets/images/Vrinda Anandam Logo High Res.png" style="width: 200px;" alt="Vrinda Anandam Logo"></a>
        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" data-toggle="collapse" data-target="#collapsibleNavbar">
    <span class="navbar-toggler-icon"></span>
  </button>
        <div class="collapse navbar-collapse" id="collapsibleNavbar" [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item" style="display: {{showItem}};">
                    <a routerLink="/" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Home</a>
                </li>
                <li class="nav-item" style="display: {{hideList}};">
                    <a style='cursor: pointer;' (click)="scrollToElementClicked('rooms')" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.hide">Rooms</a>
                </li>
                <li class="nav-item" style="display: {{hideList}};">
                    <a style='cursor: pointer;' (click)="scrollToElementClicked('packages')" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.hide">Packages</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/wedding" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Weddings</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/bhagwat" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Bhagwat</a>
                </li>
                <li class="nav-item">
                    <a routerLink="/gallery" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Gallery</a>
                </li>
                <li class="nav-item" style="display: {{hideContact}};">
                    <a style='cursor: pointer;' (click)="scrollToElementClicked('contact')" class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.hide">Contact Us</a>
                </li>

            </ul>
        </div>
    </div>
</nav>