<div *ngIf="loading" id="loadingGif" style="
opacity:0.5;
background-color:#ccc;
position:fixed;
width:100%;
height:100%;
top:0px;
left:0px;
z-index:1000;"><img src="../../../assets/loader.gif" style="position: absolute;top: 50%;left: 50%;margin: -50px 0px 0px -50px;"></div>
<div class="row py-5 mt-2">
    <div class="container">
        <div class="row">
            <div class="col-sm mx-3 align-self-center">

                <div class="single-footer-widget footer_1">
                    <div class="info-map-boxes-item" style="margin: 0; background: #636262;box-shadow:0 10px 40px -10px rgba(0, 0, 0, 0.8);">
                        <a href="https://www.google.com/maps/place/Vrinda+Anandam+Resorts/@27.59045,77.655058,15z/data=!4m8!3m7!1s0x0:0x6ac0a79548672c33!5m2!4m1!1i2!8m2!3d27.59045!4d77.655058?hl=en-GB" target="_blank">
                            <img src="../../../assets/social-icons/maps-icon.svg" class="map-icon" alt="google-map">
                        </a>
                        <h1>Reach Us</h1>
                        <p>
                            <!-- <a style="color: #ebb541;" href="tel:9810638532">
                                +91-9810638532</a>
                            <br>
                            <a style="color: #ebb541;" href="mailto:vrindaanandam@gmail.com">
                                vrindaanandam@gmail.com</a>
                            <br> -->
                            <span style="color: white">
                                VIP Road, Sunrakh, Opposite Iskon Food for life,<br> Vrindavan, Mathura,<br> Uttar
                                Pradesh (281121)

                            </span>
                        </p>
                        <br>
                        <div class="row" style="padding-left: 18px;">
                            <a href="https://www.facebook.com/vrindaanandam/" target="_blank"><img id="logo" width="15" height="25" style="margin-right: 20px;" src="../../../assets/social-icons/facebook-f-brands.png" alt="facebook-logo"></a>
                            <a  href="https://instagram.com/vrindaanandamresort_?igshid=YWJhMjlhZTc=" target="_blank"><img id="logo" width="25" height="28.56" style="margin-right: 20px;" src="../../../assets/social-icons/instagram-brands.png" alt="instagram-logo"></a>
                            <a href="https://www.youtube.com/channel/UCdXN1qqKtosTmcBLofYtSwA/?guided_help_flow=5" target="_blank"><img id="logo" width="30" height="28.56" style="margin-right: 20px;" src="../../../assets/social-icons/youtube-brands.png" alt="youtube-logo"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm mx-3 " id="contact" #contact>
                <h2 class="mt-3" style="text-align: center; color:#ebb541; text-transform: inherit;">Get in Touch</h2>
                <form #postForm="ngForm" (ngSubmit)="onCreatePost(postForm.value)" ngNativeValidate>
                    <fieldset>
                        <input placeholder="Name" type="text" tabindex="1" id="name" ngModel name="name" required>
                    </fieldset>
                    <fieldset>
                        <input placeholder="Phone Number" type="tel" tabindex="3" id="phone" ngModel name="phone" pattern="[1-9]{1}[0-9]{9}" required>
                    </fieldset>
                    <fieldset>
                        <textarea placeholder="Type your message here...." tabindex="5" id="message" ngModel name="message"></textarea>
                    </fieldset>
                    <fieldset>
                        <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
                    </fieldset>
                </form>
            </div>
            <div class="col-sm-12 mt-4">
                <p style="text-align: center; font-size: 20px;">Available From: 8 am - 10 pm</p>
                <h1 id="phone-no" style="text-align: center; font-size: 35px;">+91-897-983-0970 <br> +91-982-100-4532  </h1>
            </div>
        </div>
    </div>
</div>
