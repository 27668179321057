import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  
  constructor(private titleService: Title, private meta: Meta) {}

  title = 'Vrinda Anandam Resort @ Mathura Vrindavan near Delhi NCR ';

  ngOnInit(){
    this.titleService.setTitle(this.title);
    this.meta.updateTag({name: 'keywords', content: 'Vrinda Anandam, Vrindaanandam, Vrinda Ananadam Resort, Vrindaanandam Resort, Best Resort near Delhi NCR, Best Hotel near Delhi NCR, Best Wedding Resort near Delhi NCR, Best Destination Wedding Resort near Delhi NCR, Best Banquet Halls near Delhi NCR, Best Wedding Venues near Delhi NCR, Best Shrimad Bhagvat venue, Best Shrimad Bhagvat venue in Vrindavan, Best Shrimad Bhagvat venue in Mathura, Shrimad Bhagvat, Best Shrimad Bhagwat venue, Best Shrimad Bhagwat venue in Vrindavan, Best Shrimad Bhagwat venue in Mathura, Shrimad Bhagwat, Best Shrimad Bhagvad venue, Best Shrimad Bhagvad venue in Vrindavan, Best Shrimad Bhagwad venue in Mathura, Shrimad Bhagwad, Best Bhagwat venue, Best Bhagwat venue in Vrindavan, Best Bhagwat venue in Mathura, Bhagwat, Best Bhagvad venue, Best Bhagvad venue in Vrindavan, Best Bhagwad venue in Mathura, Bhagwad, Resorts in Vrindavan, Hotels in Vrindavan, Resort in Vrindavan, Hotel in Vrindavan, Resort in Mathura, Hotel in Mathura, Best Resort in Mathura, Best Wedding Resort in Mathura, Best Destination Wedding Resort in Mathura, Best Banquet Halls in Mathura, Best Wedding Venues in Mathura, Mathura, Vrindavan, Pool in Vrindavan, Honeymoon Resort in Vrindavan, Honeymoon Resort in Mathura, Honeymoon Resort near Delhi NCR, Best Family Resort near Delhi NCR, Best Family Resort in Vrindavan, Best Family Resort in Mathura, Family Resort, Resort with Pool near Delhi NCR, Resort with Pool in Vrindavan, Resort with pool in Mathura, Pool Parties near Delhi NCR, Pool Parties in Vrindavan, Pool Parties in Mathura'}, 'name="keywords"');
    this.meta.updateTag({name: 'description', content: 'Vrinda Anandam Resort is a beautiful family resort in Mathura Vrindavan near Delhi NCR offers Hotel, Resort, Destination Wedding, Banquet Hall and Shrimad Bhagwat Venue'}, 'name="description"');
  }
  
  scrollToElementClicked($event) {
    var x = document.getElementById($event);
    x.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
