<div class="row pt-5">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="col-sm-12">
                    <img src="../../../assets/images/Vrinda Anandam Logo High Res.png" style="width: 200px;" alt="Vrinda Anandam Logo">
                </div>
                <div class="row my-2">
                    <div class="col-sm-12 ml-4">
                        <p>
                            Address: VIP Road, Sunrakh, Opposite Iskon Food <br> for life, Vrindavan, Mathura, Uttar Pradesh (281121)
                        </p>
                        <p>
                            Phone: +91-897-983-0970
                        </p>
                        <p>
                            Email: vrindaanandam@gmail.com
                        </p>
                    </div>
                    <div class="col-sm-12 mx-4">
                        <div class="row">
                            <a href="https://www.facebook.com/vrindaanandam/" target="_blank"><img id="logo" width="15" height="25" style="margin-right: 20px; margin-left: 20px;" src="../../../assets/social-icons/facebook-f-brands.png" alt="facebook-logo"></a>
                            <a href="https://instagram.com/vrindaanandamresort_?igshid=YWJhMjlhZTc=" target="_blank"><img id="logo" width="25" height="28.56" style="margin-right: 20px;" src="../../../assets/social-icons/instagram-brands.png" alt="instagram-logo"></a>
                            <a href="https://www.youtube.com/channel/UCdXN1qqKtosTmcBLofYtSwA/?guided_help_flow=5" target="_blank"><img id="logo" width="30" height="28.56" style="margin-right: 20px;" src="../../../assets/social-icons/youtube-brands.png" alt="youtube-logo"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-2"></div>
            <div class="col-sm-4" id="partner-logo">

                <h3 style="letter-spacing: 0px !important;"> Find us On: </h3>
                <div class="row my-4">
                    <div class="col">
                        <img src="../../../assets/images/agoda.svg" alt="agoda-logo">
                    </div>
                    <div class="col">
                        <img src="../../../assets/images/trip.svg" alt="trip-advisor-logo">
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col">
                        <img src="../../../assets/images/mmt.svg" alt="makemytrip-logo">
                    </div>
                    <div class="col">
                        <img src="../../../assets/images/goibibo.svg" alt="goibibo-logo">
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-5" style="text-align: center;">
            <div class="col-sm"></div>
            <div class="col-sm"></div>
            <div class="col-sm"><a routerLink="/wedding">Weddings</a></div>
            <div class="vl"></div>
            <div class="col-sm"><a routerLink="/bhagwat">Bhagwat</a></div>
            <div class="vl"></div>
            <div class="col-sm"><a routerLink="/gallery">Gallery</a></div>
            <div class="vl"></div>
            <div class="col-sm"><a style='cursor: pointer;' (click)="scrollToElementClicked('contact')">Contact Us</a></div>
            <div class="col-sm"></div>
            <div class="col-sm"></div>
        </div>
        <div class="row my-3">
            <div class="col-sm">
                <p style="text-align: center;margin-bottom: 0px;"> Vrinda Anandam Resorts {{year}} &copy; All Rights Reserved</p>
                <p style="text-align: center;">Made with <img style="height: 25px; padding: 0 5px;" src="../../../assets/images/heart-solid.svg" alt=""> in India</p>
            </div>
        </div>
    </div>
</div>
