import { Component, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-text-corousel',
  templateUrl: './text-corousel.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./text-corousel.component.css']
})
export class TextCorouselComponent {

  texts = ['To see the Lord in everyone and to serve all according to one’s capacity through body, mind and wealth is truly the noble path.',
            'Humility is the fruit in the tree of life, which is bowing down proudly.',
          'Sadhguru is the real medium, the real destination is God.',
          'After liberation, God becomes free, but after giving devotion, God comes in bondage.',
          'The meaning of life is in the simplicity of life.',
          'Whose life is not bowing in, life is certain to break in.',
          'The memory of death creates an aptitude from the world and anesthesia to God.',
          'It is his own smile that is the best-known man in life.',
          'For the work to which your soul touches from within, it should be stopped before proceeding further.',
          'Believe that God is our ultimate partner.',
          'The knowledge of any scripture cannot be read by itself. Rather; Gurupukh only gets it.',
          'To obey parents obedience is to be their true service.',
          'Due to the absence of Satsang and the effect of kusang, there is a manifestation of defects in life.',
          'But do not come back by the moment; Their memories are always together.',
          'Do not let the inner anger, wake up the realization, then it is awake to be true. Otherwise, the eyes open in the morning when they wake up.',
          'Experience is obtained from outside but cognition is the inner thing and not the experience of God, it is the subject of cognition.',
          'It does not matter to me that God is in our favor or not, the more important thing for me is that I should be in favor of God because God is always right.',
          'Look back in life, then experience. Look ahead, look around the hope and see for yourself the divine truth and self-confidence.'];

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
}
