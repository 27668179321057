<app-header hideList="none" showItem="block" hideContact="none"></app-header>
<div class="banner">
    <div class="container">
        <div class="banner-text">
            <div class="banner-heading p-5">
                <br>
                <br>
                <br>
                <br>
                
                Oops! The page you're looking for is now beyond our reach. Head back to any of the page above.
            </div>
        </div>
    </div>
</div>