import { Component, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel-pause',
  templateUrl: './carousel-pause.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./carousel-pause.component.css']
})
export class CarouselPauseComponent{
  @Input() images:Array<{image:String, alt:String}>;
  // images = ['../../../assets/images/Weddings/EOJol4wUUAA_CK5.jpg', '../../../assets/images/Weddings/tennis_court_setup_1.jpg'];

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
}
