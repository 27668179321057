<div class="vaidik-yatra-section3">
  <div class="vaidik-section3-row">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="vaidik-section3-img" style="overflow-y: hidden;"> <img
              src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/bhagwat%2Fvedit-section3-img.png?alt=media&token=118f4530-5950-4832-8422-7af72676e0b4" alt="Vaidik Sutra"
              class=" " width="586" height="390"
              data-srcset="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/bhagwat%2Fvedit-section3-img.png?alt=media&token=118f4530-5950-4832-8422-7af72676e0b4"
              srcset="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/bhagwat%2Fvedit-section3-img.png?alt=media&token=118f4530-5950-4832-8422-7af72676e0b4">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="carousel slide" id="carousel-791974" data-ride="carousel">
            <div class="carousel-title">
              <h4>Sutra</h4>
            </div>
            <div class="carousel-inner">
              <app-text-corousel></app-text-corousel>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>