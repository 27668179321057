<app-header hideList="none" showItem="block" hideContact="none"></app-header>
<div class="row" style="height:100vh;background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/Hero.jpg?alt=media&token=86e4b019-8cb8-4d81-926e-85fcac97cbce');background-repeat: no-repeat;
background-attachment: scroll;
background-position: center center;
-webkit-background-size: cover;
-moz-background-size: cover;
-o-background-size: cover;
background-size: cover;">
    <div class="container" style="padding-top: 100px;">
        <div class="row text-center py-5">
            <div class="col-sm-12 py-5">
                <h1>Thank You</h1>
                <h2 style="color:white">We will respond to your request shortly.</h2>
                <p style="font-size:15px; color: white;"></p>
                <a id="share" href="https://wa.me/?text=Ideally located at the HEART OF VRINDAVAN, Vrinda Anandam, the first commercial resort is your.Defined by sheer elegance and style, Vrinda Anandam Resort raises the bar for luxury hospitality in Vrindavan for the first time. Located in the heart of Vrindavan, Vrinda Anandam Resort provide the ideal settings for those who would like to live the ultimate lifestyle with the same comfort and practicality as those of their own homes. http://www.vrindaanandam.in/"
                    data-action="share/whatsapp/share" class="btn btn-success" style="background-color: #ebb541; border-color: #ebb541; border-radius: 50px;"> Invite Your Friends </a>
                <a id="share1" href="mailto:?subject=Vrinda%20Anandam%20Resort&amp;body=Ideally%20locatedat%20the%20HEART%20OF%20VRINDAVAN%20The%20Braj%20Bhoomi%0D%0Ahttp://www.vrindaanandam.in/" class="btn btn-success" style="background-color: #ebb541; border-color: #ebb541; border-radius: 50px;">Share</a>
                <br><br>
            </div>

        </div>
    </div>
</div>