<app-header (messageEvent)="scrollToElementClicked($event)" hideList="none" showItem="block"></app-header>
<div class="banner">
    <div class="container">
        <div class="banner-text">
            <div class="banner-heading">
                Vrinda Anandam Resort
            </div>
            <div class="banner-sub-heading">
                Culture | Nature | Serenity
            </div>
        </div>
    </div>
</div>
<div class="row py-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <div id="about-heading" class="row mb-2" style="width: fit-content;">
                    <h1>LET THE SERENITY SOAK<br> INTO YOUR SOUL!</h1>
                </div>
                <div class="row">
                    <!-- <h4 style="padding-left:65px;text-transform: inherit;">Abode of Lord Krishna</h4>-->
                    <p id="wedding-about-text">
                        With its scenic surroundings and beautiful layout, Vrinda Anandam Resort becomes the perfect wedding venue for people who want a fairytale wedding in the clouds, overlooking the spectacular Yamuna River. The Vrinda Anandam Resort is a perfect setup as
                        you get the most luxurious destination weddings with the atmosphere, the accommodation, the indoor and outdoor venues, the coordination and experience amongst our team to plan a smooth and memorable experience.
                    </p>
                </div>
            </div>
            <div class="col-sm-6" style="align-self: center;">

                <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/Weddings%2F10047.jpg?alt=media&token=21038098-8536-42c7-8c38-89546e7560e9" alt="Wedding Ceremony at Vrinda Anandam">
            </div>
        </div>
    </div>
</div>
<div class="col-sm-12 my-5">
    <h1 style="text-align: center;">VRINDA ANANDAM WEDDINGS</h1>
</div>
<app-carousel-pause [images]="[
{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/Weddings%2FEOJol4wUUAA_CK5.jpg?alt=media&token=7be13b48-1270-410f-9f62-fd01b03464a0', alt:'Wedding at Vrinda Anandam'},
{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/Weddings%2Ftennis_court_setup_1.jpg?alt=media&token=3d8a9b37-37ea-41ea-980f-4e98b52bbf30', alt:'Wedding Stage at Vrinda Anandam'},
{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/wedding-carousel%2F143.jpg?alt=media&token=e6805d49-3d9c-42ad-bb3e-453e7c66c631', alt:'Wedding Tables at Vrinda Anandam'},
{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/wedding-carousel%2F350.jpg?alt=media&token=ae60ea83-f0b4-4cf2-a441-078b8cdd9654', alt:'Wedding at Vrinda Anandam'},
{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/wedding-carousel%2F68.jpg?alt=media&token=aaa37607-cd9e-49b5-b946-bd1978e760a1', alt:'Wedding Cake at Vrinda Anandam'},
{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/wedding-carousel%2F72.jpg?alt=media&token=33798ca4-31d0-4229-94e2-90eafe02b11b', alt:'Wedding Mandap at Vrinda Anandam'},
{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/wedding-carousel%2F74.jpg?alt=media&token=a78c8a45-75ce-48cd-b9f4-78ab8b509e90', alt:'Haldi Ceremony at Vrinda Anandam'},
{image:'https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/wedding-carousel%2F92.jpg?alt=media&token=220298ef-b6cf-4b60-bfc8-33a68bfb4b9d', alt:'Wedding Stage at Vrinda Anandam'}]"></app-carousel-pause>
<!--destination wedding-->
<div class="row py-5">
    <div class="container">
        <div class="col-sm-12 my-2">
            <h1 style="text-align: center;">MAGICAL DESTINATION WEDDINGS</h1>
        </div>
        <div class="col-sm-12 mt-2 mb-5">
            <h3 style="text-transform:inherit;text-align: center;"></h3>
        </div>
        <div class="row">
            <div class="col-sm mx-3">
                <div class="row" style="object-fit: contain; height: 232px;">
                    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/resized%2Fwed.jpg?alt=media&token=44c67ff0-ae47-4acb-91aa-52064102c502" alt="Wedding at Vrinda Anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h4 style="color:#ebb541; font-weight: 300;">WEDDINGS</h4>
                    </div>
                    <p style="font-size: 15px;">Our outdoor lawns with a capacity of over 800 pax is ideal for majestic weddings.</p>
                </div>
            </div>
            <div class="col-sm mx-3">
                <div class="row" style="object-fit: contain; height: 232px;">
                    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/resized%2Fwed-haldi.jpg?alt=media&token=c891e396-0de5-4f67-ab79-7e24dfc6370b" alt="Wedding Ceremony at Vrinda Anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h4 style="color:#ebb541;font-weight: 300;">CEREMONY</h4>
                    </div>
                    <p style="font-size: 15px;">The banquet hall is perfect for ceremonies with a capacity of 300 pax.</p>
                </div>
            </div>
            <div class="col-sm mx-3">
                <div class="row" style="object-fit: contain; height: 232px;">
                    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/resized%2Fwed-party.jpg?alt=media&token=73d087e2-e787-4af8-a051-656e4627a580" alt="Wedding Party at Vrinda Anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h4 style="color:#ebb541;font-weight: 300;">PARTIES</h4>
                    </div>
                    <p style="font-size: 15px;">Plan both pre and post wedding parties at Vrinda Andandam with a capacity of 150 pax.</p>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm mx-3">
                <div class="row" style="object-fit: contain; height: 232px;">
                    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/Weddings%2FPool%20Parties%20(2).jpg?alt=media&token=0cd7d846-14a9-46da-b307-482248f70e33" alt="Pool Party at Vrinda Anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h4 style="color:#ebb541;font-weight: 300;">POOL PARTIES</h4>
                    </div>
                    <p style="font-size: 15px;">Host smaller ceremonies or parties at our exquisite pool side. Ideal for a gathering of 100 people.</p>
                </div>
            </div>

            <div class="col-sm mx-3">
                <div class="row" style="object-fit: contain; height: 232px;">
                    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/resized%2Fwed-room.jpg?alt=media&token=563a940f-5892-4943-9fb5-11972d4a7a4e" alt="Accomodation at Vrinda Anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h4 style="color:#ebb541; font-weight: 300;">ACCOMMODATION</h4>
                    </div>
                    <p style="font-size: 15px;">With over 80 rooms on the property and with a capacity of 250 people, get the most wholesome Destination Wedding experience.
                    </p>
                </div>
            </div>
            <div class="col-sm mx-3">
                <div class="row" style="object-fit: contain; height: 232px;">
                    <img width="100%" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/Weddings%2FFoodnew%20(1).jpg?alt=media&token=48ed76e0-70db-49d7-8e72-45aa84d9a13a" alt="Catering at Vrinda Anandam">
                </div>
                <div class="row px-3 py-3" style="text-align: center;">
                    <div class="col-sm">
                        <h4 style="color:#ebb541;font-weight: 300;">CATERING</h4>
                    </div>
                    <p style="font-size: 15px;">Vrinda Andandam’s menu for the occasion with a fine balance of Indian delights and global cuisines is designed to make the wedding stand-out and leave the guests with a lingering taste.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!--destination wedding ends-->
<hr>
<app-contact></app-contact>
<app-scroll-top></app-scroll-top>
<app-footer (messageEvent)="scrollToElementClicked($event)"></app-footer>
