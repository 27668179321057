import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<String>();
  year : string;

  constructor() { }
  

  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();
  }


  scrollToElementClicked(el: string) {
    this.messageEvent.emit(el);
 }

}
