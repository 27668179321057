import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() colorValue: string;
  @Input() hideList:string;
  @Input() showItem:string;
  @Input() hideContact:string;
  @Output() messageEvent = new EventEmitter<String>();
  public isCollapsed = true;
  
  constructor() { }

  ngOnInit(): void {
    this.colorValue = "rgba(0, 0, 0, 0.5)";
  }

  scrollToElementClicked(el: string) {
     this.messageEvent.emit(el);
     let element: HTMLElement = document.getElementsByClassName( 'navbar-toggler' )[ 0 ] as HTMLElement;
      if ( element.getAttribute( 'aria-expanded' ) == 'true' ) {
        element.click();
    }
  }
}
