<app-header (messageEvent)="scrollToElementClicked($event)" hideList="none" showItem="block" colorValue='rgba(0, 0, 0, 0.5)'></app-header>
<div class="col" id="desktop-gallery">
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_1.jpg?alt=media&token=370ca006-499d-467d-93a9-f1cf86f51faf" alt="Accommodation Buildings at Vrinda Anandam">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_2.jpg?alt=media&token=7218e546-49d0-41c4-b163-589c2f91ea3b" alt="Front View Accommodation Building at Vrinda Anandam">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_3.jpg?alt=media&token=6e7ac5d2-805a-42f1-96e6-4061980ad37d" alt="Aerial View Campus at Vrinda Anandam">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_4.jpg?alt=media&token=2af36c6c-abcd-4786-8d68-922b3134eb42" alt="Accomodation at Vrinda Anandam">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_5.jpg?alt=media&token=81758abb-3de1-4608-860b-d62f223cc3e8" alt="Accommodation at Vrinda Anandam">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_6.jpg?alt=media&token=49f04b34-7105-4b72-b244-9e6b60cc67fc" alt="Swimming Pool at Vrinda Anandam">
                </div>
            </div>
        </div>
    </div>
    <!-- Change Here -->
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2FPool.jpg?alt=media&token=c1cea1ad-1884-4339-9b84-84f9f5a870c1" alt="Swimming Pool at Vrinda Anandam">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery5.jpg?alt=media&token=596fbd4d-94a8-428b-84d3-2e283391d52d" alt="Lawn at Vrinda Anandam">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2Fswing_night.jpg?alt=media&token=b52a2e73-212d-4c73-a1e3-d8be184a4535" alt="Park at Vrinda Anandam">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_7.jpg?alt=media&token=503c7d13-d9d2-494e-8d83-acd699776119" alt="Vrinda Anandam">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_8.jpg?alt=media&token=12bbc410-ac23-41de-949c-f2085eae891d" alt="Reception Desk at Vrinda Anandam">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_9.jpg?alt=media&token=86dd2da0-7c06-4d45-9da4-62857e1a3413" alt="Restaurant at Vrinda Anandam">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="row">
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2FIMG_20200209_200701.jpg?alt=media&token=b1370d66-21c5-4253-b0ee-a2041040fa6a" alt="Premium Room at Vrinda Anandam">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2FIMG_20200209_203621.jpg?alt=media&token=e9af7bc5-7beb-430e-b7a4-15a568bc37f4" alt="Premium Bed at Vrinda Anandam Premium Room">
                </div>
                <div class="col px-0">
                    <img class="img-fluid desktop-image" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2FIMG_20200209_200739.jpg?alt=media&token=31c5950c-5f6a-45cf-a1ae-0437410d11d0" alt="Premium Room at Vrinda Anandam">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="gallery" id="gallery">
    <!--gallery 1-->
    <div class="mb-3 pics animation all 2">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_1.jpg?alt=media&token=370ca006-499d-467d-93a9-f1cf86f51faf" alt="Accommodation Buildings at Vrinda Anandam">
    </div>
    <!--gallery 2-->
    <div class="mb-3 pics animation all 1">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_2.jpg?alt=media&token=7218e546-49d0-41c4-b163-589c2f91ea3b" alt="Front View Accommodation Building at Vrinda Anandam">
    </div>
    <!--gallery 3-->
    <div class="mb-3 pics animation all 2">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_3.jpg?alt=media&token=6e7ac5d2-805a-42f1-96e6-4061980ad37d" alt="Aerial View Campus at Vrinda Anandam">
    </div>
    <!--gallery 4-->
    <div class="mb-3 pics animation all 2">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_4.jpg?alt=media&token=2af36c6c-abcd-4786-8d68-922b3134eb42" alt="Accomodation at Vrinda Anandam">
    </div>
    <!--gallery 5-->
    <div class="mb-3 pics animation all 1">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_5.jpg?alt=media&token=81758abb-3de1-4608-860b-d62f223cc3e8" alt="Accomodation at Vrinda Anandam">
    </div>
    <!--gallery 6-->
    <div class="mb-3 pics animation all 2">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_6.jpg?alt=media&token=49f04b34-7105-4b72-b244-9e6b60cc67fc" alt="Swimming Pool at Vrinda Anandam">
    </div>

    <!-- Change Here -->
    <div class="mb-3 pics animation all 1">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2FPool.jpg?alt=media&token=c1cea1ad-1884-4339-9b84-84f9f5a870c1" alt="Swimming Pool at Vrinda Anandam">
    </div>

    <div class="mb-3 pics animation all 1">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery5.jpg?alt=media&token=596fbd4d-94a8-428b-84d3-2e283391d52d" alt="Lawn at Vrinda Anandam">
    </div>

    <div class="mb-3 pics animation all 1">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2Fswing_night.jpg?alt=media&token=b52a2e73-212d-4c73-a1e3-d8be184a4535" alt="Park at Vrinda Anandam">
    </div>


    <!--gallery 7-->
    <div class="mb-3 pics animation all 1">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_7.jpg?alt=media&token=503c7d13-d9d2-494e-8d83-acd699776119" alt="Vrinda Anandam">
    </div>
    <!--gallery 8-->
    <div class="mb-3 pics animation all 1">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_8.jpg?alt=media&token=12bbc410-ac23-41de-949c-f2085eae891d" alt="Reception Desk at Vrinda Anandam">
    </div>
    <!--gallery 9-->
    <div class="mb-3 pics animation all 2">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2Fgallery-new%2FGallery_9.jpg?alt=media&token=86dd2da0-7c06-4d45-9da4-62857e1a3413" alt="Restaurant at Vrinda Anandam">
    </div>
    <!--gallery 10-->
    <div class="mb-3 pics animation all 1">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2FIMG_20200209_200701.jpg?alt=media&token=b1370d66-21c5-4253-b0ee-a2041040fa6a" alt="Premium Room at Vrinda Anandam">
    </div>

    <!--gallery 11-->
    <div class="mb-3 pics animation all 2">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2FIMG_20200209_200739.jpg?alt=media&token=31c5950c-5f6a-45cf-a1ae-0437410d11d0" alt="Premium Bed at Vrinda Anandam Premium Room">
    </div>

    <!--gallery 12-->
    <div class="mb-3 pics animation all 2">
        <img class="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/vrindaanadam.appspot.com/o/gallery%2FIMG_20200209_203621.jpg?alt=media&token=e9af7bc5-7beb-430e-b7a4-15a568bc37f4" alt="Premium Room at Vrinda Anandam">
    </div>
</div>
<div class="row">
    <div class="col py-5 text-center">
        <h5 style="color: #323233">For more images of Vrinda Anandam Resort please check our Instagram Handle</h5>
        <br>
        <button type="button" class="btn" onclick="window.location.href='https://instagram.com/vrindaanandam?igshid=1waam6silhcol';" style="background: #ebb541; color: white; padding: 1rem 1.5rem; outline: none;">Visit Instagram</button>
        <br>
        <hr>
    </div>
</div>
<app-contact></app-contact>
<app-scroll-top></app-scroll-top>
<app-footer (messageEvent)="scrollToElementClicked($event)"></app-footer>
