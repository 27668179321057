import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-bhagvat',
  templateUrl: './bhagvat.component.html',
  styleUrls: ['./bhagvat.component.css']
})
export class BhagvatComponent implements OnInit {

  items = [
    { title: 'Slidekbdslkjf 1' },
    { title: 'Slide 2' },
    { title: 'Slide 3' },
    { title: 'Slide 4' },
    { title: 'Slide 5' },
  ]

  constructor(private titleService: Title, private meta: Meta) {}

  title = 'Vrinda Anandam Shrimad Bhagwat Venue @ Vrindavan ';

  ngOnInit(){
    this.titleService.setTitle(this.title);
    this.meta.updateTag({name: 'keywords', content: 'Vrinda Anandam, Vrindaanandam, Vrinda Ananadam Resort, Vrindaanandam Resort, Best Resort near Delhi NCR, Best Hotel near Delhi NCR, Best Shrimad Bhagvat venue, Best Shrimad Bhagvat venue in Vrindavan, Best Shrimad Bhagvat venue in Mathura, Shrimad Bhagvat, Best Shrimad Bhagwat venue, Best Shrimad Bhagwat venue in Vrindavan, Best Shrimad Bhagwat venue in Mathura, Shrimad Bhagwat, Best Shrimad Bhagvad venue, Best Shrimad Bhagvad venue in Vrindavan, Best Shrimad Bhagwad venue in Mathura, Shrimad Bhagwad, Best Bhagwat venue, Best Bhagwat venue in Vrindavan, Best Bhagwat venue in Mathura, Bhagwat, Best Bhagvad venue, Best Bhagvad venue in Vrindavan, Best Bhagwad venue in Mathura, Bhagwad, Resorts in Vrindavan, Hotels in Vrindavan, Resort in Vrindavan, Hotel in Vrindavan, Resort in Mathura, Hotel in Mathura, Best Resort in Mathura, Best Family Resort near Delhi NCR, Best Family Resort in Vrindavan, Best Family Resort in Mathura, Family Resort'}, 'name="keywords"');
    this.meta.updateTag({name: 'description', content: 'Vrinda Anandam Resort is the best Shrimad Bhagwat Venue in Mathura Vrindavan near Delhi NCR offers Hotel, Resort, Banquet Hall'}, 'name="description"');
  }

  scrollToElementClicked($event) {
    var x = document.getElementById($event);
    x.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }


}
